import React from 'react';

function Button(props) {
  return (
    <button className="bg-transparent text-light border-2 border-light font-bold py-3 px-8 rounded-xl transition duration-300 cubic-bezier(.4, 0, 0.2, 1); hover:bg-light hover:text-thirdAccent">
      {props.text}
    </button>
  );
}

export default Button;