import React from 'react';
import styles from "./../styles";
import Container from './Container';
import {character} from "./../assets";

function Features() {
  const brandName = 'Luxchipsbet';

  return (
      <div className="bg-dark text-white">
      <Container>
        <div className='flex flex-col md:flex-row items-center justify-between mt-10'>
          <div className='flex-1 mr-20 mb-10 md:mb-0'>
            <h2 className={`${styles.heading2} text-center uppercase font-extrabold`}>Features</h2>
            <img src={character} alt="" className='mx-auto max-w-[300px] w-full'/>
          </div>
          <div className="flex flex-col">
            <div className="border-t-2 border-white border-dashed pt-1 pb-6">
              <div>
                <h3 className="sm:text-[28px] text-[21px] font-bold mb-2 text-white">Top Pokies</h3>
                <p className="text-white">At <span className="website">{brandName}</span>, you will find a fantastic selection of free Pokies. Just complete the super-fast sign up process and start playing now!</p>
              </div>
            </div>
            <div className="border-t-2 border-white border-dashed pt-1 pb-6">
              <div>
                <h3 className="sm:text-[28px] text-[21px] font-bold mb-2 text-white">Free Pokies</h3>
                <p className="text-white">No real money is required to play Pokies at <span className="website">{brandName}</span>. All you have to do is register and get spinning in an instant.</p>
              </div>
            </div>
            <div className="border-t-2 border-white border-dashed pt-1 pb-6">
              <div>
                <h3 className="sm:text-[28px] text-[21px] font-bold mb-2 text-white">Just For Fun</h3>
                <p className="text-white">Here you’ll find the ultimate Vegas experience on mobile, desktop and tablet. Our Pokies are 100% free and no real money can be won.</p>
              </div>
            </div>
            
          </div>
        </div>
        </Container>
        </div>
  );
}

export default Features;