import React from 'react';
import {Link} from "react-router-dom";
import { ctaBg } from '../assets';

function CTA() {
  return (
    <div className="bg-dark sm:grid sm:grid-cols-2 justify-center items-center px-10 md:px-20">
      <div className="sm:pt-10 pb-10 sm:pb-0 text-center">
      <h2 className="text-mainAccent text-5xl font-mainFont font-extrabold mb-8 italic">Does not involve the use of Real Money!</h2>
      <Link to="/games"><button className="bg-mainAccent text-thirdAccent font-bold rounded-lg py-3 px-6 transition-colors duration-300">Play now!</button></Link>
      </div>
      <img src={ctaBg} alt="" />
    </div>
  );
}

export default CTA;