import React from 'react';
import styles from "./../styles";
import Container from './Container';

export default function Terms() {
  return (
    <div className='bg-dark text-light'>
    <Container>
    <div className={`${styles.section} pb-10`}>
        <h2 className={`${styles.heading2}`}>Terms & Conditions</h2>
        <p>Welcome to our website offering free to play slot games! Please read these terms and conditions carefully before using our website. By accessing or using our website, you agree to be bound by these terms and conditions and our privacy policy. If you do not agree to these terms and conditions, please do not use our website.</p>
        <h4>Introduction</h4>
        <p>Our website is an online platform that provides users with access to a variety of free to play slot games. No real money is required to play these games, and no real money can be won. Our games are intended for entertainment purposes only.</p>
        <h4>Eligibility</h4>
        <p>Our website is not intended for use by individuals under the age of 18. If you are under the age of 18, you are not permitted to use our website.</p>
        <h4>Use of our website</h4>
        <p>You may use our website and the content and materials contained on our website solely for personal, non-commercial purposes. You may not use our website or the content and materials contained on our website for any other purpose, including any commercial purpose, without our express written consent.</p>
        <h4>Intellectual property</h4>
        <p>All content and materials on our website, including but not limited to text, graphics, images, and software, are the property of our website or our licensors and are protected by copyright and other intellectual property laws. You may not use any content or materials on our website for any commercial purpose without the express written consent of our website.</p>
        <h4>Disclaimer of warranties</h4>
        <p>Our website and the content and materials contained on our website are provided on an &#8220;as is&#8221; and &#8220;as available&#8221; basis. We make no representations or warranties of any kind, express or implied, as to the operation of our website or the information, content, materials, or products included on our website. We will not be liable for any damages of any kind arising from the use of our website, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>
        <h4>Limitation of liability</h4>
        <p>We will not be liable for any damages of any kind arising from the use of our website, including but not limited to direct, indirect, incidental, punitive, and consequential damages, even if we have been advised of the possibility of such damages.</p>
        <h4>Indemnification</h4>
        <p>You agree to indemnify and hold us and our affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys&#8217; fees, made by any third party due to or arising out of your use of our website, your violation of these terms and conditions, or your violation of any rights of another.</p>
        <h4>Governing law</h4>
        <p>These terms and conditions and your use of our website are governed by and construed in accordance with the laws of the United States.</p>
        <h4>Changes to these terms and conditions</h4>
        <p>We reserve the right to change these terms and conditions at any time without notice. Any changes to these terms and conditions will be effective immediately upon posting on our website. It is your responsibility to review these terms and conditions periodically for any changes. Your continued use of our website after any changes to these terms and conditions are posted will be deemed acceptance of those changes.</p>
        <h4>Termination</h4>
        <p>We reserve the right, in our sole discretion, to terminate your access to our website at any time and for any reason, without notice.</p>
        <h4>Entire agreement</h4>
        <p>These terms and conditions constitute the entire agreement between you and our website and govern your use of our website, superseding any prior agreements between you and our website.</p>
        <h4>Severability</h4>
        <p>If any provision of these terms and conditions is found to be invalid or unenforceable, that provision will be enforced</p>
          
    </div>
    </Container>
    </div>
  )
}
