import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {favicon} from "../assets";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const website = 'Luxchipsbet';

    return (
      <>
      <header className="text-thirdAccent bg-dark border-b-[0.5px] border-white flex items-center justify-between px-4 md:px-18 lg:px-24 py-5 shadow-md">
        <div className="logo flex items-center">
          <a href="/" className="custom-3d-font mr-3 font-bold font-headingFont tracking-wider italic uppercase sm:text-[31px] text-[21px] text-secondaryAccent">
            {website}</a>
            <img src={favicon} alt="" className='w-[60px] md:w-[70px]' /> 
        </div>
        {/* navigation */}

    <nav className="flex items-center justify-between p-4">
      {/* Navigation Menu for desktop screens */}
      <ul className="hidden md:flex uppercase font-accentFont font-bold">
        <li className="mx-4">
          <Link to="/" className='link-hover-animation hover:text-mainAccent'>Home</Link>
        </li>
        <li className="mx-4">
          <Link to="/games" className='link-hover-animation hover:text-mainAccent'>Games</Link>
        </li>
        <li className="mx-4">
          <Link to="/about" className='link-hover-animation hover:text-mainAccent'>About Us</Link>
        </li>
        <li className="mx-4">
          <Link to="/contacts" className='link-hover-animation hover:text-mainAccent'>Contact Us</Link>
        </li>
      </ul>

      {/* Hamburger Menu for mobile screens */}
      <div className="md:hidden">
        <button className="text-light focus:outline-none" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
          <path d="M6 18L18 6M6 6l12 12" stroke="#1364d0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ display: isMenuOpen ? 'block' : 'none' }} />
          <path d="M4 6h16M4 12h16M4 18h16" stroke="#1364d0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ display: isMenuOpen ? 'none' : 'block' }} />
      </svg>
        </button>
      </div>
    </nav>

        {/* end navigation */}
      </header>
       {/* Navigation Menu for mobile screens */}
     {isMenuOpen &&
          <ul className="w-full md:hidden bg-dark bottom-0 left-0 w-full py-4 px-4 flex flex-col text-light shadow-lg">
            <li className="py-2 border-b-[.1px] border-[#edf2f45e]">
              <Link to="/">Home</Link>
            </li>
            <li className="py-2 border-b-[.1px] border-[#edf2f45e]">
              <Link to="/games">Games</Link>
            </li>
            <li className="py-2 border-b-[.1px] border-[#edf2f45e]">
              <Link to="/about">About Us</Link>
            </li>
            <li className="my-2">
              <Link to="/contacts">Contact Us</Link>
            </li>
          </ul>
        }
        </>
      
    );
  };
  
  export default Header;