import React from 'react';
import styles from "./../styles";
import Container from './Container';
import CTA from './CTA';
import Features from './Features';
import Hero from './Hero';

export default function About() {
  const brandName = "Luxchipsbet";

  return (
    <div className='bg-dark text-light'>
    <Hero title="About Us"/>
    <Container>
        <div className='text-blue pt-8 pb-0 md:pt-16 md:pb-10'>
          <p className={`${styles.paragraph} mb-10`}>
          <span className="website">{brandName}</span> is your home for free online pokies. Here at <span className="website">{brandName}</span> we aim to deliver the best online pokies experience without any of the risk involved with playing with your own money. All our pokies are 100% free and no real money can be won. Our team has many years of experience playing online pokies and have used that experience to build the most action packed pokies possible.
        </p>
        <p className={`${styles.paragraph} mb-10`}>
        The pokies at <span className="website">{brandName}</span> are available on mobile, desktop and tablet and so can be played wherever and whenever you like. It’s our aim to bring you the best casino experience possible, so if there’s anything you think we can change or improve, do not hesitate to contact us using the online contact form – our small but perfectly formed team is available 24/7 to answer any questions you may have.
        </p>
        </div>
    </Container>
      <CTA/>
      <Container>
        <Features/>
      </Container>
      </div>
  )
}
