import React from 'react';
import { Link } from 'react-router-dom';
import { heroBg } from '../assets';
import { MdNoAdultContent } from 'react-icons/md';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const brandName = 'Luxchipsbet';

  return (
    <footer
      className="text-light bg-blue text-[0.9rem] md:text-[1rem] flex flex-col gap-4 font-poppins uppercase justify-evenly items-center px-10 py-16"
      style={{
        backgroundImage: `linear-gradient(to right, rgb(65 53 67 / 60%), rgb(45 39 39 / 60%)), url(${heroBg})`,
        backgroundPosition: 'center bottom',
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat'
      }}
    >
      <ul className="navigation md:flex text-center font-bold">
        <li className="mx-4">
          <Link to="/">Home</Link>
        </li>
        <li className="mx-4">
          <Link to="/games">Games</Link>
        </li>
        <li className="mx-4">
          <Link to="/about">About Us</Link>
        </li>
        <li className="mx-4">
          <Link to="/contacts">Contact Us</Link>
        </li>
      </ul>
      <ul className="policy__list text-center font-bold">
        <li className="policy__item">
          <Link to="/terms">Terms & Conditions</Link>
        </li>
        <li className="policy__item mt-2">
          <Link to="/policy">Privacy Policy</Link>
        </li>
      </ul>
      <p className="text-mainAccent text-xl font-mainFont">Practicing casino gaming for fun does not imply future success in real money gambling. <br/> Is intended for persons over 18 years of age and is for entertainment purposes only.</p>
      <p className="opacity-70 capitalize">@Copyright <span>{currentYear}</span>.
        All rights reserved</p>
    </footer>
  );
}
